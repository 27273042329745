import React from "react";

const Terms = () => {
    return (
        <div>
            <div className="container">
                <h4 className="mt-5">1. Definitions</h4>
                <ul>
                    <li>
                        <strong>The Company</strong>: Supercheap Mobile
                        Mechanic, the person who is the vendor of the services
                        and or goods to the customer.
                    </li>
                    <li>
                        <strong>The Customer</strong>: the person contracting
                        for goods and services to be supplied by the Company.
                    </li>
                    <li>
                        <strong>Consumer</strong>: a Customer, being an
                        individual who, for the purposes of the purchase, is
                        acting wholly or mainly outside of their trade,
                        business, craft or profession.
                    </li>
                    <li>
                        <strong>Goods</strong>: means all things to be sold by
                        the Company to the Customer.
                    </li>
                    <li>
                        <strong>Services</strong>: means all services, including
                        repairs, provided by the company to the Customer.
                    </li>
                </ul>
                <h4>2. Whole Contract</h4>
                <p>
                    These terms shall represent the whole contract between the
                    Company and the Customer. They may be varied only by written
                    agreement between the parties.
                </p>

                <h4>3. Interpretation</h4>
                <p>
                    The singular shall include the plural and the male shall
                    include the female or business entity as may be appropriate.
                </p>

                <h4>4. Enforceability</h4>
                <p>
                    In the event of any one or more of these terms and
                    conditions being declared unenforceable, the remaining terms
                    and conditions shall nonetheless remain in full force and
                    effect.
                </p>

                <h4>5. Estimate</h4>
                <ol>
                    <li>
                        Unless specifically agreed in writing, all work is
                        agreed on an estimate basis.
                    </li>
                    <li>
                        Unless specifically agreed in writing, time for
                        completion of any work is not essential.
                    </li>
                    <li>
                        Any estimate is based on the costs of labour and
                        materials relevant at this date and the Company reserves
                        the right to alter the charges to meet any variations,
                        whether due to increase in wages (controlled by national
                        agreements), materials or increased cost from any other
                        cause outside of the Company reasonable control.
                    </li>
                    <li>
                        Should any additional work or materials be found
                        necessary in the course of these repairs or subsequent
                        testing, it will be necessary to make an extra charge.
                        Where this additional work involves a substantial
                        increase in the amount estimated, a supplementary
                        estimate will be submitted for acceptance.
                    </li>
                </ol>

                <h4>6. Paintwork</h4>
                <p>
                    Where new paintwork is required and the metal work is found
                    to be rusted, every reasonable precaution will be taken to
                    prevent this penetrating through after completion of
                    painting, but no guarantee can be given in this respect. If
                    partial paintwork only is required, every endeavour will be
                    made to match the existing colour schemes, but no guarantee
                    can be given of a perfect colour match.
                </p>

                <h4>7. Goods Supplied</h4>
                <ol>
                    <li>
                        Where the service includes the provision of goods, the
                        Company reserves the right to impose a handling charge
                        on goods returned for credit (which have been correctly
                        supplied to order).
                    </li>
                    <li>
                        All 'Special Orders' correctly supplied and of
                        satisfactory quality will not be accepted for credit.
                        Goods supplied that are of satisfactory quality will not
                        be accepted for credit more than 7 working days from the
                        date of issue of an invoice.
                    </li>
                    <li>
                        Worn units will only be accepted in a clean and oil free
                        condition.
                    </li>
                    <li>
                        All claims or queries pertaining to an invoice must be
                        made within 7 working days of issue of an invoice
                        quoting the invoice number.
                    </li>
                    <li>
                        In the event of cancellation, for any reason, the
                        customer agrees to return any Goods to the Company's
                        premises.
                    </li>
                    <li>
                        The title in any goods/services shall pass when payment
                        has been received by the Company (and all
                        cheques/bankers drafts cleared) and not on delivery.
                        Until such time as the property in the goods passes to
                        the Customer, the Company shall be entitled at any time
                        to require the Customer to deliver up the goods and if
                        the Customer fails to do so forthwith, to enter upon any
                        premises of the Customer or any third party (including
                        where the Customer is in administration/ receivership).
                        The Customer must store the goods separately from other
                        goods until paid for.
                    </li>
                </ol>
                <h4>8. Variation</h4>
                <p>
                    Any variation agreed between the Company and the Customer
                    regarding the Goods to be supplied shall be deemed to be an
                    amendment to this Contract and shall not constitute a new
                    contract.
                </p>
                <h4>9. Delivery</h4>
                <ol>
                    <li>
                        The Company shall give the estimated time for the repair
                        of a vehicle and shall make every effort to inform the
                        Customer if this estimated time cannot be met, although
                        the Company can accept no responsibility for delays
                        outside its control.
                    </li>
                    <li>
                        Unless otherwise agreed in writing delivery of the Goods
                        shall take place at the Dealer's premises.
                    </li>
                </ol>
                <h4>10. Payment</h4>
                <p>
                    Payment in respect of any services or work undertaken shall
                    be made on or prior to taking delivery of the vehicle unless
                    a credit account has been opened.
                </p>
            </div>
        </div>
    );
};

export default Terms;
