import React from "react";
import { Reveal } from "react-reveal";

const RepairService = () => {
    return (
        <section id="repair-service" className="chat_core_features_area sec_pad" style={{backgroundColor: 'white', paddingBottom: "0px"}}>
            <div className="hosting_title chat_title text-center">
                <Reveal effect="fadeInUp" duration={3000}>
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Choose from <span>200+</span> auto repair services</h2>
                </Reveal>
            </div>
            <div className="text-center">
                <Reveal effect="fadeInUp" duration={1300}>
                    <img className="img-fluid" src={require("../../img/home/pictures/techs.png")} alt="Technicians" style={{width: "600px"}} />
                </Reveal>   
            </div>
        </section>
    )
}

export default RepairService;