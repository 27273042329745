import React from "react";

const SupportPolicySection = () => {
    return (
        <div>
            <div className="container">
                <p className="mt-5">
                    Supercheap Mobile Mechanic's primary approach in supporting
                    our customers is through the "Contact Us" function that is
                    available within the on our site. To ensure we are
                    consistently achieving best-in-class CSAT via Intercom, we
                    have implemented response times of 2 hours or less. Our
                    support team's average time to first response time is about
                    one hour.
                </p>
                <h4>OUR HOURS OF OPERATION:</h4>
                <ul>
                    <li>Standard Support Hours:</li>
                    <ul>
                        <li>Mon. - Fri. 6am to 6pm</li>
                        <li>
                            Anticipated response within 2 hours from time of
                            receipt
                        </li>
                    </ul>
                    <li>Non-Standard Support Hours:</li>
                    <ul>
                        <li>Evenings: 4pm - 6pm</li>
                        <li>Weekends: 10am - 4pm</li>
                        <li>
                            Anticipated response will be addressed within 5
                            hours from time of receipt from 10am - 4pm
                        </li>
                    </ul>
                </ul>
            </div>
        </div>
    );
};

export default SupportPolicySection;
