import React, { Component } from "react";
import { Link } from "react-router-dom";

class TrackingProductItem4 extends Component {
    render() {
        let {
            itemClass,
            rowclass,
            image,
        } = this.props;
        return (
            <div className={`tracking_pr_item ${itemClass}`}>
                <div className={`row align-items-center ${rowclass}`}>
                    <div className="tracking_bg text-centre">
                        <img
                            src={require("../../img/home/" + image)}
                            alt=""
                            width="450"
                            height="450"
                            className="img-fluid"
                            useMap="#image4"
                        />

                        <map name="image4">
                            <Link to={`/service/Old-Car-Removal/old-car-removal`}><area shape="poly" coords="0,0,450,0,450,450,0,0" href="" alt="#" /></Link>
                            <Link to={`/service/Recycle-Junk-Car/recycle-junk-cars`} ><area shape="poly" coords="0,0,0,450,450,450,0,0" href="#" alt="" /></Link>
                        </map>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrackingProductItem4;
