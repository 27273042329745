import React from "react";

const PrivacyPolicySection = () => {
    return (
        <div>
            <div className="container">
                <p className="mt-5">
                    This privacy policy explains how Supercheap Mobile Mechanic
                    use the personal information we collect about you.
                </p>
                <p>
                    To comply with the General Data Protection Regulation and to
                    prevent unauthorised access, we follow procedures regarding
                    the collection, storage and disclosure of information that
                    you have given us.
                </p>
                <h4>What information do we collect about you?</h4>
                <p>
                    We collect the following personal information about you when
                    you make an enquiry or place an order for products or
                    services.
                </p>
                <ul>
                    <li>Name</li>
                    <li>Vehicle make &amp; model</li>
                    <li>Address</li>
                    <li>Email</li>
                    <li>Service Type</li>
                    <li>Phone Number</li>
                </ul>
                <p>Website usage information is collected using cookies.</p>
                <h4>How we will use the information about you?</h4>
                <p>
                    The personal information we collect about you is used to
                    process your enquiry, provide you with a product or service
                    and to manage your account. We may also use your personal
                    information to keep you informed about the status of your
                    order or service being provided.
                </p>
                <p>
                    In certain instances we are obliged to pass your personal
                    details to third parties, namely:
                </p>
                <ul>
                    <li>
                        When selling you a vehicle your personal details will be
                        passed to the DVLA for vehicle registration and taxation
                        purposes.
                    </li>
                    <li>
                        When producing vehicle number plates your personal
                        details will be retained by Sandhurst Autoprint Limited
                        in encrypted format for a period of three years.
                    </li>
                </ul>
                <h4>Marketing</h4>
                <p>
                    If you agree, we would like to send you vehicle service and
                    MOT reminders as well as occasional marketing information
                    about other products, services and events that may be of
                    interest to you.
                </p>
                <p>
                    Spratley's will not share your information for marketing
                    purposes with other companies.
                </p>
                <p>
                    You have the right at any time to stop us from contacting
                    you for marketing purposes.
                </p>
                <p>
                    If you no longer wish to be contacted for marketing purposes
                    please contact us by:
                </p>
                <ul>
                    <li>By telephone: 0473 834 227</li>
                    <li>
                        By email:
                        <a href="mailto:ali@supercheapmechanic.com.au">
                            ali@supercheapmechanic.com.au
                        </a>
                    </li>
                </ul>
                <h4>Access to your information and correction</h4>
                <p>
                    Under the regulations you have the right to request copies
                    of your personal information, to seek rectification of
                    inaccurate data, the erasure of your data, or object to or
                    restrict the use of your data, and where our system allows,
                    to request copies of your data in digital format.
                </p>
                <h4>How to contact us</h4>
                <p>
                    Please contact us if you have any questions about our
                    privacy policy or information we hold about you:
                </p>
                <ul>
                    <li>By telephone: 0473 834 227</li>
                    <li>
                        By email:
                        <a href="mailto:ali@supercheapmechanic.com.au">
                            ali@supercheapmechanic.com.au
                        </a>
                    </li>
                </ul>
                <h4>Changes to our privacy policy</h4>
                <p>
                    We keep our privacy policy under regular review and we place
                    any updates on this web page.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicySection;
