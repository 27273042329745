import React, { useContext } from "react";
import { Helmet } from "react-helmet";

// Components
import CustomNavbar from "../components/CustomNavbar";
import DesignBanner from "../components/Banner/DesignBanner";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
// import ServiceData from "../components/Service/ServiceData";
// import HomeProcess from "./HomeProcess";
import TrackingProduct from "../components/Features/TrackingProduct";
import CoreFeatures from "../components/Features/CoreFeatures";
import Rating from "../components/Home/Rating";
import RepairService from "../components/Home/RepairService";
import BookService from "../components/Home/BookService";
import Testimonail from "../components/Home/Testimonial";
import Quote from "../components/Home/Quote";

export const Home = ({ match }) => {
    let title = "";
    let metaDescription = "";
    let metaKeywords = "";
    if (match.path == "/") {
        title = "Car Service and Repair in Hallam 3803";
        metaDescription =
            "Super Cheap Mechanics Come To You Anywhere In Hampton Park 3976 and Offer Comprehensive Car Service and Repair";
        metaKeywords = "Car Service and Repair in Hallam 3803";
        // canonicalUrl = "https://supercheapmechanic.com.au/" ; 
    } 
    
    return (
        <div className="body_wrapper">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                {/* <link rel="canonical" href={canonicalUrl} /> */}
            </Helmet>

            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
            {/* <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" nClass="ml-auto mr-auto" /> */}
            <DesignBanner />
            {/* <HomeProcess /> */}
            <Rating />
            <RepairService />
            <BookService />
            <CoreFeatures></CoreFeatures>
            {/* <TrackingProduct></TrackingProduct> */}
            {/* <Service ServiceData={ServiceData} /> */}
            {/* <Subscribe FooterData={FooterData} /> */}
            <Testimonail />
            <Quote />
            <Footer FooterData={FooterData} />
        </div>
    );
};
