import React from "react";
import { Link } from "react-router-dom";

function RepairListItem({ id, name, image, desc, slug }) {
    return (
        <div key={id}>
            <div class="card">
                <img
                    src={require(`../../img/home/icons/${image}.png`)}
                    class="card-img-top"
                    alt="..."
                    style={{
                        width: "100px",
                        margin: "15px auto",
                        paddingBottom: "25px",
                    }}
                />
                <div class="card-body">
                    <h5 class="card-title">{name}</h5>
                    <p style={{ height: "80px" }} class="card-text">
                        {desc}
                    </p>
                    <Link to={`/service/${slug}/${image}`}>
                        <button class="btn btn-primary">Book Now</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default RepairListItem;
