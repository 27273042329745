import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ServiceDetails = ({ title, icon }) => {
    let text = "";
    let newTitle = title
        .split("-")
        .join(" ")
        .replace(/\b\w/g, (x) => x.toUpperCase());
    const [status, setStatus] = useState(undefined);
    const form = useRef();
    const name = useRef(null);
    const email = useRef(null);
    const phone_number = useRef(null);
    const make = useRef(null);
    const service = useRef(null);
    const sub_urb = useRef(null);
    const message = useRef(null);

    if (newTitle == "Air Conditioning") {
        text =
            "Supercheap Mobile Mechanic offers reliable air conditioning services for your vehicle. Our team of experienced technicians is equipped with the latest tools and techniques to diagnose and repair any issues with your car's AC system. We offer competitive pricing and a convenient mobile service that comes to you, so you can get back on the road as quickly as possible.<br><br>Regular maintenance is essential to ensure that your car's AC system runs smoothly. At Supercheap Mobile Mechanic, we offer comprehensive AC tune-ups to keep your system in top condition. Our tune-ups include a thorough inspection of all AC components, such as the compressor, refrigerant levels, condenser, and evaporator. With our regular maintenance services, you can enjoy comfortable driving all year round.<br><br>If you're facing issues with your car's AC system, don't hesitate to call Supercheap Mobile Mechanic. We offer fast and efficient AC repairs for all makes and models of vehicles. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done. Trust us to keep you cool on the road.";
    } else if (newTitle == "Auto Glass") {
        text =
            "Supercheap Mobile Mechanic provides professional auto glass services for your vehicle. Our team of skilled technicians is equipped to handle any auto glass repair or replacement needs you may have. We offer a wide range of services, from repairing small chips and cracks to replacing entire windshields. Plus, our mobile service comes to you, so you can get back on the road as quickly as possible.<br><br>Don't let a cracked or damaged windshield put you at risk on the road. At Supercheap Mobile Mechanic, we prioritize your safety by providing reliable auto glass repair and replacement services. We use high-quality materials and techniques to ensure that your new windshield is installed correctly and securely.<br><br>Our team understands that auto glass issues can be frustrating and inconvenient. That's why we strive to provide fast and efficient service, so you can get back to your day as soon as possible. Contact Supercheap Mobile Mechanic today for all your auto glass needs.";
    } else if (newTitle == "Battery") {
        text =
            "Supercheap Mobile Mechanic offers reliable battery services for your vehicle. Our team of experienced technicians is equipped to handle any battery issues you may have, from testing and maintenance to replacement. We use high-quality batteries from trusted brands to ensure that your car starts every time.<br><br>If you're experiencing issues with your car's battery, don't wait to get it checked. Our team offers fast and efficient battery testing services to diagnose any issues with your battery. We'll provide you with an honest explanation of what needs to be done and work with you to find the most cost-effective solution.<br><br>At Supercheap Mobile Mechanic, we understand that battery issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Contact us today for all your battery needs.";
    } else if (newTitle == "Body") {
        text =
            "Supercheap Mobile Mechanic provides professional body repair services for your vehicle. Our team of skilled technicians is equipped to handle any body repair needs you may have, from minor dents and scratches to major collision damage. We use high-quality materials and techniques to ensure that your car looks and performs like new.<br><br>We understand that body damage can be stressful and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Our team will provide you with an honest assessment of the damage and work with you to find the most cost-effective solution.<br><br>At Supercheap Mobile Mechanic, we prioritize your satisfaction and strive to provide fast and efficient service. Contact us today for all your body repair needs and let us help restore your car to its original condition.";
    } else if (newTitle == "Brakes") {
        text =
            "Supercheap Mobile Mechanic offers reliable brake services for your vehicle. Our team of experienced technicians is equipped to handle any brake issues you may have, from brake pad replacements to full brake system overhauls. We use high-quality parts and materials to ensure that your brakes are functioning safely and effectively.<br><br>Regular maintenance is essential to ensure that your brakes are in top condition. At Supercheap Mobile Mechanic, we offer comprehensive brake tune-ups to keep your system running smoothly. Our tune-ups include a thorough inspection of all brake components, such as brake pads, rotors, calipers, and brake fluid. With our regular maintenance services, you can enjoy reliable braking performance and peace of mind on the road.<br><br>If you're experiencing issues with your brakes, don't hesitate to call Supercheap Mobile Mechanic. We offer fast and efficient brake repairs for all makes and models of vehicles. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done. Trust us to keep you safe on the road.";
    } else if (newTitle == "Clutch") {
        text =
            "Supercheap Mobile Mechanic provides expert clutch repair services for your vehicle. Our team of skilled technicians is equipped to handle any clutch issues you may have, from clutch cable replacements to complete clutch system overhauls. We use high-quality parts and materials to ensure that your clutch is functioning smoothly and effectively.<br><br>If you're experiencing issues with your clutch, don't wait to get it checked. A worn or damaged clutch can result in decreased performance and even put you at risk on the road. At Supercheap Mobile Mechanic, we offer fast and efficient clutch repairs for all makes and models of vehicles. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done.<br><br>We understand that clutch issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Trust Supercheap Mobile Mechanic to provide reliable clutch repair services that get you back to driving smoothly. Contact us today for all your clutch repair needs.";
    } else if (newTitle == "Cooling") {
        text =
            "Supercheap Mobile Mechanic offers professional radiator cooling services for your vehicle. Our team of experienced technicians is equipped to handle any radiator issues you may have, from coolant leaks to complete radiator replacements. We use high-quality parts and materials to ensure that your radiator is functioning properly and efficiently.<br><br>Regular maintenance is essential to ensure that your radiator is in top condition. At Supercheap Mobile Mechanic, we offer comprehensive radiator cooling system tune-ups to keep your system running smoothly. Our tune-ups include a thorough inspection of all radiator components, such as the radiator, water pump, and hoses. With our regular maintenance services, you can enjoy reliable cooling performance and peace of mind on the road.<br><br>If you're experiencing issues with your radiator cooling system, don't hesitate to call Supercheap Mobile Mechanic. We offer fast and efficient repairs for all makes and models of vehicles. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done. Trust us to keep your engine running cool and smoothly.";
    } else if (newTitle == "Electrical") {
        text =
            "Supercheap Mobile Mechanic offers comprehensive electrical services for your vehicle. Our team of experienced technicians is equipped to handle any electrical issues you may have, from battery replacements to complete rewiring. We use high-quality parts and materials to ensure that your electrical system is functioning properly and efficiently.<br><br>If you're experiencing issues with your electrical system, don't wait to get it checked. A malfunctioning electrical system can cause a range of problems, from decreased performance to safety hazards. At Supercheap Mobile Mechanic, we offer fast and efficient electrical repairs for all makes and models of vehicles. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done.<br><br>We understand that electrical issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Trust Supercheap Mobile Mechanic to provide reliable electrical repair services that get you back to driving safely. Contact us today for all your electrical repair needs.";
    } else if (newTitle == "Engine") {
        text =
            "Supercheap Mobile Mechanic provides expert engine repair services for your vehicle. Our team of skilled technicians is equipped to handle any engine issues you may have, from simple tune-ups to complete engine overhauls. We use high-quality parts and materials to ensure that your engine is functioning smoothly and effectively.<br><br>If you're experiencing issues with your engine, don't wait to get it checked. A malfunctioning engine can cause a range of problems, from decreased performance to safety hazards. At Supercheap Mobile Mechanic, we offer fast and efficient engine repairs for all makes and models of vehicles. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done.<br><br>We understand that engine issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Trust Supercheap Mobile Mechanic to provide reliable engine repair services that get you back to driving smoothly. Contact us today for all your engine repair needs.";
    } else if (newTitle == "Installation") {
        text =
            "Supercheap Mobile Mechanic offers expert installation services for a wide range of automotive components. Our team of experienced technicians is equipped to handle any installation job, from brake pads to suspension systems. We use high-quality parts and materials to ensure that your new components are installed correctly and efficiently.<br><br>Whether you need a new battery, a new air conditioning system, or a new set of tires, Supercheap Mobile Mechanic has you covered. Our team will quickly and efficiently install your new components, ensuring that they function properly and safely. We take pride in our work and guarantee your satisfaction with every installation job we perform.<br><br>We understand that your time is valuable. That's why we offer a convenient mobile service that comes to you, so you don't have to waste time bringing your vehicle to us. Trust Supercheap Mobile Mechanic to provide reliable and efficient installation services that get you back on the road with confidence. Contact us today for all your installation needs.";
    } else if (newTitle == "Lights") {
        text =
            "Supercheap Mobile Mechanic provides expert lighting services for your vehicle. Our team of skilled technicians is equipped to handle any lighting issues you may have, from bulb replacements to complete lighting system overhauls. We use high-quality parts and materials to ensure that your lighting system is functioning properly and efficiently.<br><br>If you're experiencing issues with your vehicle's lights, it's important to get it checked as soon as possible. Poorly functioning lights can cause safety hazards, making it difficult for you to see the road ahead and for other drivers to see you. At Supercheap Mobile Mechanic, we offer fast and efficient lighting repairs for all makes and models of vehicles. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done.<br><br>We understand that lighting issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Trust Supercheap Mobile Mechanic to provide reliable lighting repair services that get you back to driving safely. Contact us today for all your lighting repair needs.";
    } else if (newTitle == "Scratch Dent Paint") {
        text =
            "Supercheap Mobile Mechanic offers expert scratch, dent, and paint repair services for your vehicle. Our team of skilled technicians is equipped to handle any cosmetic issues you may have, from minor scratches and dents to major body damage. We use high-quality paint and materials to ensure that your vehicle looks as good as new.<br><br>We understand that scratches, dents, and other cosmetic damage can be frustrating and can decrease the value of your vehicle. That's why we offer a convenient mobile service that comes to you, so you can get your vehicle repaired quickly and efficiently. Trust Supercheap Mobile Mechanic to provide reliable and affordable cosmetic repairs that get your vehicle looking great again.<br><br>Whether you need a simple scratch repair or a complete paint job, Supercheap Mobile Mechanic has you covered. Our team will quickly diagnose the problem and provide you with an honest explanation of what needs to be done. Contact us today for all your scratch, dent, and paint repair needs.";
    } else if (newTitle == "Suspension Steering") {
        text =
            "Supercheap Mobile Mechanic provides expert suspension and steering services for your vehicle. Our team of skilled technicians is equipped to handle any suspension or steering issues you may have, from worn out shock absorbers to damaged steering components. We use high-quality parts and materials to ensure that your vehicle's suspension and steering systems are functioning properly and efficiently.<br><br>If you're experiencing issues with your vehicle's suspension or steering, it's important to get it checked as soon as possible. Poorly functioning suspension or steering can cause safety hazards, making it difficult for you to control your vehicle and causing premature wear on your tires. At Supercheap Mobile Mechanic, we offer fast and efficient suspension and steering repairs for all makes and models of vehicles.<br><br>We understand that suspension and steering issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Trust Supercheap Mobile Mechanic to provide reliable suspension and steering repair services that get you back to driving safely. Contact us today for all your suspension and steering repair needs.";
    } else if (newTitle == "Timing Belt") {
        text =
            "Supercheap Mobile Mechanic offers expert timing belt replacement services for your vehicle. Our team of skilled technicians is equipped to handle any timing belt issues you may have, from routine replacements to complex repairs. We use high-quality timing belts and parts to ensure that your vehicle's engine is running smoothly and efficiently.<br><br>The timing belt is an important component of your vehicle's engine, and a damaged or worn-out timing belt can cause serious damage to your engine. At Supercheap Mobile Mechanic, we recommend having your timing belt inspected regularly and replaced according to the manufacturer's recommendations. Our team will quickly diagnose any timing belt issues and provide you with an honest explanation of what needs to be done.<br><br>We understand that timing belt issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get your timing belt replaced quickly and efficiently. Trust Supercheap Mobile Mechanic to provide reliable timing belt replacement services that keep your engine running smoothly. Contact us today for all your timing belt replacement needs.";
    } else if (newTitle == "Transmission") {
        text =
            "Supercheap Mobile Mechanic provides expert transmission services for your vehicle. Our team of skilled technicians is equipped to handle any transmission issues you may have, from routine maintenance to complex repairs. We use high-quality parts and materials to ensure that your vehicle's transmission is functioning properly and efficiently.<br><br>If you're experiencing issues with your vehicle's transmission, it's important to get it checked as soon as possible. Transmission issues can cause safety hazards, making it difficult for you to control your vehicle and causing premature wear on your tires. At Supercheap Mobile Mechanic, we offer fast and efficient transmission repairs for all makes and models of vehicles.<br><br>We understand that transmission issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get back on the road as soon as possible. Trust Supercheap Mobile Mechanic to provide reliable transmission repair services that get you back to driving safely. Contact us today for all your transmission repair needs.";
    } else if (newTitle == "Tyre") {
        text =
            "Supercheap Mobile Mechanic provides expert tyre services for your vehicle. Our team of skilled technicians is equipped to handle any tyre issues you may have, from routine tyre maintenance to complex repairs. We use high-quality tyres and parts to ensure that your vehicle is safe and efficient on the road.<br><br>If you're experiencing issues with your vehicle's tyres, it's important to get them checked as soon as possible. Worn or damaged tyres can cause safety hazards, making it difficult for you to control your vehicle and causing premature wear on your brakes. At Supercheap Mobile Mechanic, we offer fast and efficient tyre repairs and replacements for all makes and models of vehicles.<br><br>We understand that tyre issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get your tyres repaired or replaced quickly and efficiently. Trust Supercheap Mobile Mechanic to provide reliable tyre services that keep you safe on the road. Contact us today for all your tyre repair and replacement needs.";
    } else if (newTitle == "Wheel") {
        text =
            "Supercheap Mobile Mechanic provides expert wheel services for your vehicle. Our team of skilled technicians is equipped to handle any wheel issues you may have, from routine maintenance to complex repairs. We use high-quality wheels and parts to ensure that your vehicle is safe and efficient on the road.<br><br>If you're experiencing issues with your vehicle's wheels, it's important to get them checked as soon as possible. Damaged or misaligned wheels can cause safety hazards, making it difficult for you to control your vehicle and causing premature wear on your tyres. At Supercheap Mobile Mechanic, we offer fast and efficient wheel repairs and replacements for all makes and models of vehicles.<br><br>We understand that wheel issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get your wheels repaired or replaced quickly and efficiently. Trust Supercheap Mobile Mechanic to provide reliable wheel services that keep you safe on the road. Contact us today for all your wheel repair and replacement needs.";
    } else if (newTitle == "Window Tinting") {
        text =
            "Supercheap Mobile Mechanic offers professional window tinting services to enhance the appearance and comfort of your vehicle. Our team of experienced technicians uses high-quality tinting films that block harmful UV rays and reduce the glare and heat inside your vehicle.<br><br>We understand the importance of maintaining your privacy while driving, and that's why we offer a wide range of tinting options to choose from. We also offer custom tinting solutions to suit your specific needs and preferences.<br><br>Our mobile service is designed to provide you with the utmost convenience. We come to your location and perform the window tinting service in a timely and efficient manner, ensuring that you can get back on the road as soon as possible. Contact Supercheap Mobile Mechanic today to schedule your window tinting appointment.";
    } else if (newTitle == "Wipers") {
        text =
            "Supercheap Mobile Mechanic provides expert wiper services for your vehicle. Our team of skilled technicians is equipped to handle any wiper issues you may have, from routine maintenance to complex repairs. We use high-quality wiper blades and parts to ensure that your vehicle is safe and efficient on the road.<br><br>If you're experiencing issues with your wipers, it's important to get them checked as soon as possible. Faulty wipers can cause safety hazards, reducing your visibility and making it difficult for you to see the road ahead. At Supercheap Mobile Mechanic, we offer fast and efficient wiper repairs and replacements for all makes and models of vehicles.<br><br>We understand that wiper issues can be frustrating and inconvenient. That's why we offer a convenient mobile service that comes to you, so you can get your wipers repaired or replaced quickly and efficiently. Trust Supercheap Mobile Mechanic to provide reliable wiper services that keep you safe on the road. Contact us today for all your wiper repair and replacement needs.";
    }

    function sendEmail(e) {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_dg6x5dk",
                "template_8c2so0c",
                form.current,
                "mbqeBuSbZZVyPqePZ"
            )
            .then(
                (result) => {
                    setStatus({ type: "success" });
                    name.current.value = "";
                    email.current.value = "";
                    phone_number.current.value = "";
                    make.current.value = "";
                    service.current.value = "";
                    sub_urb.current.value = "";
                    message.current.value = "";
                },
                (error) => {
                    setStatus({ type: "error" });
                }
            );
    }

    return (
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 pr_70">
                        {icon && (
                            <img
                                className="mb-3"
                                src={require("../../img/home/icons/" +
                                    icon +
                                    ".png")}
                                alt=""
                                width="250"
                                height="250"
                            />
                        )}
                    </div>
                    <div className="col-lg-4">
                        <div className="details_content">
                            <div className="sec_title">
                                {text !== "" && (
                                    <p
                                        className="f_400 f_size_15"
                                        dangerouslySetInnerHTML={{
                                            __html: text,
                                        }}
                                    ></p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-lg-4">
                        <div
                            className="job_info"
                            style={{
                                background: "#FFE1AA",
                            }}
                        >
                            <form
                                className="quote_form"
                                onSubmit={sendEmail}
                                ref={form}
                            >
                                <div className="row">
                                    {status?.type === "success" && (
                                        <p className="alert alert-success">
                                            Message Send Successfully
                                        </p>
                                    )}
                                    {status?.type === "error" && (
                                        <p className="alert alert-danger">
                                            Message Not Sent
                                        </p>
                                    )}
                                    <div className="form-group col-12 text_box">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Your Name"
                                            required
                                            style={{
                                                border: "1px solid #ffebce",
                                            }}
                                            ref={name}
                                        />
                                    </div>
                                    <div className="col-12 text_box">
                                        <input
                                            type="email"
                                            class="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Your Email"
                                            required
                                            style={{
                                                border: "1px solid #ffebce",
                                            }}
                                            ref={email}
                                        />
                                    </div>
                                    <div className="form-group col-6 text_box">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            name="phone_number"
                                            placeholder="Your Phone Number"
                                            required
                                            style={{
                                                border: "1px solid #ffebce",
                                            }}
                                            ref={phone_number}
                                        />
                                    </div>
                                    <div className="form-group col-6 text_box">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="sub-urb"
                                            name="sub_urb"
                                            placeholder="Your Suburb"
                                            required
                                            style={{
                                                border: "1px solid #ffebce",
                                            }}
                                            ref={sub_urb}
                                        />
                                    </div>
                                    <div className="col-12 text_box">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="make-model"
                                            name="make"
                                            placeholder="Year Make Model"
                                            required
                                            style={{
                                                border: "1px solid #ffebce",
                                            }}
                                            ref={make}
                                        />
                                    </div>
                                    <div className="col-12 text_box">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="service"
                                            name="service"
                                            placeholder="Main Service - Battery, AC, Auto Glass, etc."
                                            required
                                            value={newTitle}
                                            style={{
                                                border: "1px solid #ffebce",
                                            }}
                                            ref={service}
                                        />
                                    </div>
                                    <div className="form-group col-md-12 text_box">
                                        <textarea
                                            type="text"
                                            class="form-control message"
                                            id="message"
                                            name="message"
                                            placeholder="Your Message"
                                            required
                                            rows="1"
                                            style={{
                                                border: "1px solid #ffebce",
                                            }}
                                            ref={message}
                                        />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn_three" type="submit">
                                        Get Quote Now
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ServiceDetails;
