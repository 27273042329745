import React from "react";
import { Helmet } from "react-helmet";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Contacts from "../components/Contacts";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";

const About = () => {
    return (
        <div className="body_wrapper">
            <Helmet>
                <title>Contact</title>
                <meta
                    name="description"
                    content="Super Cheap Mechanic|Contact"
                />
                <meta
                    name="keywords"
                    content="free, connect, contact, phone no, email"
                />
            </Helmet>

            <CustomNavbar
                slogo="sticky_logo"
                cClass="custom_container p0"
                mClass="menu_four"
                nClass="w_menu"
            />
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Contact Us"
                Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
            />
            <Contacts />
            <FooterTwo FooterData={FooterData} />
        </div>
    );
};
export default About;
