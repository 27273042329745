const RepairData = {
    Repairs: [
        {
            id: 1,
            slug: "air-conditioning",
            name: "Air Conditioning",
            image: "air-conditioning",
            description:
                "We fixes and maintains vehicle's cooling and heating system.",
        },
        {
            id: 2,
            slug: "auto-glass",
            name: "Auto Glass",
            image: "auto-glass",
            description: "Expert auto glass repair to keep you safe.",
        },
        {
            id: 3,
            slug: "battery",
            name: "Battery",
            image: "battery",
            description:
                "Professional battery repair services for all car models.",
        },
        {
            id: 4,
            slug: "body",
            name: "Body",
            image: "body-repair",
            description: "High-quality car body repair for a seamless finish.",
        },
        {
            id: 5,
            slug: "brakes",
            name: "Brakes",
            image: "brakes",
            description: "Expert brake repair for safe and smooth driving.",
        },
        {
            id: 6,
            slug: "clutch",
            name: "Clutch",
            image: "clutch",
            description: "Efficient clutch repair for smooth gear shifting.",
        },
        {
            id: 7,
            slug: "cooling",
            name: "Cooling",
            image: "cooling",
            description:
                "Expert cooling system repair for optimal engine performance.",
        },
        {
            id: 8,
            slug: "electrical",
            name: "Electrical",
            image: "electrical",
            description:
                "Effective electrical system repair for reliable vehicle performance.",
        },
        {
            id: 9,
            slug: "engine",
            name: "Engine",
            image: "engine",
            description:
                "Dependable engine repair for peak performance and longevity.",
        },
        {
            id: 10,
            slug: "installation",
            name: "Installation",
            image: "installation",
            description:
                "Expert installation of various car parts and accessories.",
        },
        {
            id: 11,
            slug: "lights",
            name: "Lights",
            image: "lights",
            description:
                "Efficient car light repair for optimal visibility and safety.",
        },
        {
            id: 12,
            slug: "scratch-dent-paint",
            name: "Scratch, Dent & Paint",
            image: "scratch-dent-paint",
            description:
                "Seamless scratch, dent, and paint repair for flawless finish.",
        },
        {
            id: 13,
            slug: "suspension-steering",
            name: "Suspension / Steering",
            image: "suspension",
            description: "Smooth ride with our expert car suspension service.",
        },
        {
            id: 14,
            slug: "timing-belt",
            name: "Timing Belt",
            image: "timing-belt",
            description:
                "Reliable timing belt service for efficient engine operation.",
        },
        {
            id: 15,
            slug: "transmission",
            name: "Transmission",
            image: "transmission",
            description:
                "Expert transmission service for smooth shifting and performance.",
        },
        {
            id: 16,
            slug: "tyre",
            name: "Tyre",
            image: "tyre",
            description:
                "Quality tyre repair and replacement for safer driving.",
        },
        {
            id: 17,
            slug: "wheel",
            name: "Wheel",
            image: "wheel",
            description:
                "Expert wheel repair and replacement for better handling.",
        },
        {
            id: 18,
            slug: "window-tinting",
            name: "Window Tinting",
            image: "window-tinting",
            description:
                "Professional window tinting and removal for style and comfort.",
        },
        {
            id: 19,
            slug: "wipers",
            name: "Wipers",
            image: "wipers",
            description:
                "Clear visibility with efficient wiper repair and replacement.",
        },
    ],
};

export default RepairData;
