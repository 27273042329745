import React from "react";
import { Reveal } from "react-reveal";
import Slider from "react-slick";

import FooterData from "../Footer/FooterData";

const Testimonail = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section id="testimonials" className="chat_core_features_area sec_pad" style={{paddingTop:"0px"}}>
            <div className="container">
                <div className="hosting_title chat_title text-center">
                    <Reveal effect="fadeInUp" duration={3000}>
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                            Our <span>satisfied</span> clients
                        </h2>
                    </Reveal>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Slider {...settings}>
                            {FooterData.TestimonialText.map((client) => {
                                return (
                                    <div className="pl-30">
                                        <div className="item" key={client.id}>
                                            <div
                                                class="card text-center"
                                                style={{
                                                    border: "0px",
                                                    borderRadius: "25px",
                                                }}
                                            >
                                                <img
                                                    src={require("../../img/" +
                                                        client.image)}
                                                    class="card-img-top"
                                                    alt="..."
                                                    style={{
                                                        width: "75px",
                                                        height: "75px",
                                                        margin: "10px auto",
                                                    }}
                                                />
                                                <div class="card-body">
                                                    <h5 class="card-title">
                                                        {client.authorName}
                                                    </h5>
                                                    <p class="card-text">
                                                        {client.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonail;
