import React from "react";
import { Reveal } from "react-reveal";
import { Link } from "react-router-dom";

const Quote = () => {
    return (
        <section
            id="home-quote"
            className="chat_core_features_area sec_pad"
            style={{ backgroundColor: "white", paddingBottom: "0px" }}
        >
            <div>
                <img
                    className="home-quote-img img-fluid"
                    src={require("../../img/home/pictures/mechanic.jpg")}
                    alt="Mechanic"
                />
                <div className="hosting_title chat_title text-center home-quote-title">
                    <Reveal effect="fadeInUp" duration={3000}>
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                            We will <span>Repair</span> your car while you're
                            doing your work
                        </h2>
                    </Reveal>
                </div>
                <div className="text-center home-quote-btn">
                    <Link to="/Get-Quote">
                        <button className="btn_three">Get a Quote</button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Quote;
