import React, { Component, useContext } from "react";
import Reveal from "react-reveal/Reveal/";

function Breadcrumb({
    breadcrumbClass,
    Ptitle,
}) {
    return (
        <section className={`${breadcrumbClass}`} >
            <div className="container">
                <div className="breadcrumb_content text-center">
                    <Reveal effect="fadeInUp">
                        <h1 className="f_p f_700 f_size_100 w_color l_height90 mb_90">
                            {Ptitle.split("-").join(" ").replace(/\b\w/g, x => x.toUpperCase())}
                        </h1>
                    </Reveal>
                </div>
            </div>
        </section>
    );
}

export default Breadcrumb;
