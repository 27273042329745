import React from "react";

const FaqSection = () => {
    return (
        <section className="faq_area bg_color sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div
                            className="tab-content faq_content"
                            id="myTabContent"
                        >
                            <div
                                className="tab-pane fade show active"
                                id="purchas"
                                role="tabpanel"
                                aria-labelledby="purchas-tab"
                            >
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">
                                    Most Popular Questions
                                </h3>
                                <div id="accordion">
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingOne"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    className="btn btn-link"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    How do I book an
                                                    appointment?
                                                    <i className="ti-plus"></i>
                                                    <i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div
                                            id="collapseOne"
                                            className="collapse show"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordion"
                                        >
                                            <div className="card-body">
                                                You can book an appointment
                                                directly through our website or
                                                by calling us. We'll ask for
                                                vehicle information, and the
                                                service you need. We'll provide
                                                you with an instant quote and
                                                available appointment times, so
                                                you can book the date and time
                                                that works best for you.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingTwo"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    className="btn btn-link collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                >
                                                    How do I get a quote?
                                                    <i className="ti-plus"></i>
                                                    <i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div
                                            id="collapseTwo"
                                            className="collapse"
                                            aria-labelledby="headingTwo"
                                            data-parent="#accordion"
                                        >
                                            <div className="card-body">
                                                You can get an instant quote
                                                through our website. We'll ask
                                                for your vehicle information,
                                                and the service you need. You'll
                                                receive an instant quote online,
                                                or if we need to prepare a
                                                special quote for you, you will
                                                receive it within 24 hours after
                                                your request.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingThree"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    className="btn btn-link collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree"
                                                >
                                                    How do I reschedule or
                                                    cancel my appointment?
                                                    <i className="ti-plus"></i>
                                                    <i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div
                                            id="collapseThree"
                                            className="collapse"
                                            aria-labelledby="headingThree"
                                            data-parent="#accordion"
                                        >
                                            <div className="card-body">
                                                We understand that schedules
                                                change. If you're unable to make
                                                your appointment, please
                                                reschedule or cancel via the
                                                quote form or by calling us. If
                                                your appointment is within 24
                                                hours, you will incur a $50 fee
                                                for canceling or rescheduling.
                                                This fee can be applied to
                                                another appointment booked
                                                within 7 days.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingfour"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    className="btn btn-link collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapsefour"
                                                    aria-expanded="false"
                                                    aria-controls="collapsefour"
                                                >
                                                    My technician is late. Who
                                                    should I contact?
                                                    <i className="ti-plus"></i>
                                                    <i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div
                                            id="collapsefour"
                                            className="collapse"
                                            aria-labelledby="headingfour"
                                            data-parent="#accordion"
                                        >
                                            <div className="card-body">
                                                We always provide a 1-hour
                                                window for your technician to
                                                arrive. If you have waited
                                                beyond the 1-hour window, please
                                                contact us and we'll be happy to
                                                assist you.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default FaqSection;
