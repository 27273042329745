const FooterData = {
    CompanyWidget: [
        {
            id: 1,
            title: "Get In Touch",
            description:
                "Don’t miss any updates of our new templates and extensions.!",
        },
    ],
    AboutWidget: [
        {
            id: 1,
            title: "Let's Meet",
            menuItems: [
                {
                    id: 1,
                    url: "#",
                    text: "MON – FRI | 06:00AM – 06:00PM",
                },
                {
                    id: 2,
                    url: "#",
                    text: "SATURDAY | 06:00AM – 04:00PM",
                },
                // {
                //   id: 3,
                //   url: '#',
                //   text: 'ios App',
                // },
                // {
                //   id: 4,
                //   url: '#',
                //   text: 'Desktop',
                // },
                // {
                //   id: 5,
                //   url: '#',
                //   text: 'Projects',
                // },
                // {
                //     id: 6,
                //     url: '#',
                //     text: 'My tasks',
                // },
            ],
        },
    ],
    HelpWidget: [
        {
            id: 1,
            title: "Help",
            menuItems: [
                {
                    id: 1,
                    url: "/Faq",
                    text: "FAQ",
                },
                {
                    id: 2,
                    url: "/Terms&Conditions",
                    text: "Term & conditions",
                },
                {
                    id: 3,
                    url: "/Support-Policy",
                    text: "Support Policy",
                },
                {
                    id: 4,
                    url: "/Privacy-Policy",
                    text: "Privacy",
                },
            ],
        },
    ],
    termsCondition: [
        {
            id: 1,
            title: "Team Solutions",
            menuItems: [
                {
                    id: 1,
                    url: "#",
                    text: "Support",
                },
                {
                    id: 2,
                    url: "#",
                    text: "Sales",
                },
                {
                    id: 3,
                    url: "#",
                    text: "Report Abuse",
                },
                {
                    id: 4,
                    url: "#",
                    text: "System Status",
                },
            ],
        },
    ],
    SocialLinks: [
        {
            id: 1,
            title: "Connect With Us",
            menuItems: [
                {
                    id: 1,
                    url: "https://www.facebook.com/a1autorecyclingqld/",
                    icon: "ti-facebook",
                },
                {
                    id: 2,
                    url: "https://twitter.com/a1utorecycling",
                    icon: "ti-twitter-alt",
                },
                {
                    id: 3,
                    url: "https://www.instagram.com/a1autorecyclingau/",
                    icon: "ti-instagram",
                },
                // {
                //   id: 4,
                //   url: '#',
                //   icon: 'ti-pinterest',
                // }
            ],
        },
    ],
    socialIcon: [
        {
            id: 1,
            url: "https://www.facebook.com/a1autorecyclingqld/",
            icon: "ti-facebook",
        },
        {
            id: 2,
            url: "https://twitter.com/a1utorecycling",
            icon: "ti-twitter-alt",
        },
        // {
        //   id: 3,
        //   url: '#',
        //   icon: 'ti-vimeo-alt',
        // },
        // {
        //   id: 4,
        //   url: '#',
        //   icon: 'ti-pinterest',
        // }
    ],
    copywrite: "© SaasLand Inc.. 2019 All rights reserved.",
    TestimonialText: [
        {
            id: 1,
            image: "new/member_01.jpg",
            description:
                "I've been using Supercheap Mobile Mechanic for a long time now, and I couldn't be happier with their service. They are always prompt, professional, and extremely knowledgeable. They've helped me with everything from routine maintenance to major repairs, and their prices are always reasonable. I wouldn't trust anyone else with my car!",
            authorName: "Shane Smith",
            authorPost: "UI/UX designer",
        },
        {
            id: 2,
            image: "new/member_02.jpg",
            description:
                "I had an urgent issue with my car and needed a mechanic right away. I called Supercheap Mobile Mechanic and they were able to come to my location within the hour. They quickly diagnosed the problem and had me back on the road in no time. Their customer service is top-notch, and I appreciate how they go above and beyond to take care of their customers.",
            authorName: "Sarah Charlotte",
            authorPost: "UI/UX designer",
        },
        {
            id: 3,
            image: "new/member_03.jpg",
            description:
                "Supercheap Mobile Mechanic is the only mechanic I trust with my classic car. They have a deep understanding of older vehicles and always use high-quality parts to keep my car running smoothly. They are passionate about what they do, and it shows in the quality of their work. I highly recommend them to anyone in need of a reliable and trustworthy mechanic.",
            authorName: "Olivia Grace",
            authorPost: "UI/UX designer",
        },
    ],
    aboutLinks: [
        {
            id: 1,
            url: "#",
            item: "Developer",
        },
        {
            id: 2,
            url: "#",
            item: "Blog",
        },
        {
            id: 3,
            url: "#",
            item: "Investor",
        },
        {
            id: 4,
            url: "#",
            item: "Sitemap",
        },
        {
            id: 5,
            url: "#",
            item: "Jobs",
        },
    ],
    helpLinks: [
        {
            id: 1,
            url: "#",
            item: "Help and Contact",
        },
        {
            id: 2,
            url: "#",
            item: "Fees",
        },
        {
            id: 3,
            url: "#",
            item: "Security",
        },
        {
            id: 4,
            url: "#",
            item: "App",
        },
        {
            id: 5,
            url: "#",
            item: "Shop",
        },
    ],
    PrivacyLinks: [
        {
            id: 1,
            url: "#",
            item: "Privacy Policy",
        },
        {
            id: 2,
            url: "#",
            item: "Legal Agreement",
        },
        {
            id: 3,
            url: "#",
            item: "Feedback",
        },
    ],
    about: [
        {
            id: 1,
            url: "#",
            text: "Company",
        },
        {
            id: 2,
            url: "#",
            text: "Leadership",
        },
        {
            id: 3,
            url: "#",
            text: "Diversity",
        },
        {
            id: 4,
            url: "#",
            text: "Jobs",
        },
        {
            id: 5,
            url: "#",
            text: "Press",
        },
        {
            id: 6,
            url: "#",
            text: "Wavelength",
        },
    ],
    Solution: [
        {
            id: 1,
            url: "#",
            text: "Project Management",
        },
        {
            id: 2,
            url: "#",
            text: "Agile",
        },
        {
            id: 3,
            url: "#",
            text: "Task Management",
        },
        {
            id: 4,
            url: "#",
            text: "Reporting",
        },
        {
            id: 5,
            url: "#",
            text: "Work Tracking",
        },
        {
            id: 6,
            url: "#",
            text: "See All Uses",
        },
    ],
    teamSolution: [
        {
            id: 1,
            url: "#",
            text: "Engineering",
        },
        {
            id: 2,
            url: "#",
            text: "Designers",
        },
        {
            id: 3,
            url: "#",
            text: "Sales",
        },
        {
            id: 4,
            url: "#",
            text: "Developers",
        },
        {
            id: 5,
            url: "#",
            text: "Marketing",
        },
        {
            id: 6,
            url: "#",
            text: "See All team",
        },
    ],
};
export default FooterData;
