import React, { useState, useRef } from "react";
import { Reveal } from "react-reveal";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const GetQuote = () => {
    const [status, setStatus] = useState();
    const form = useRef();
    const name = useRef(null);
    const email = useRef(null);
    const phoneNumber = useRef(null);
    const suburb = useRef(null);
    const yearMakeModel = useRef(null);
    const service = useRef(null);
    const message = useRef(null);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_dg6x5dk",
                "template_8c2so0c",
                form.current,
                "mbqeBuSbZZVyPqePZ"
            )
            .then(
                (result) => {
                    setStatus({ type: "success" });
                    name.current.value = "";
                    email.current.value = "";
                    phoneNumber.current.value = "";
                    suburb.current.value = "";
                    yearMakeModel.current.value = "";
                    service.current.value = "";
                    message.current.value = "";
                },
                (error) => {
                    setStatus({ type: "error" });
                }
            );
    };

    return (
        <section className="contact_info_area sec_pad bg_color">
            <div className="container">
                <div className="row">
                    <div className="contact_form col-lg-8">
                        <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">
                            Get Free Quote Now
                        </h2>
                        {status?.type === "success" && (
                            <p className="alert alert-success">
                                Message Send Succesfully
                            </p>
                        )}
                        {status?.type === "error" && (
                            <p className="alert alert-danger">
                                Message Not Sent
                            </p>
                        )}
                        <form
                            onSubmit={sendEmail}
                            className="contact_form_box"
                            method="post"
                            id="contactForm"
                            ref={form}
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group text_box">
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Your Name"
                                            ref={name}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group text_box">
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder="Your Email"
                                            ref={email}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group text_box">
                                        <input
                                            type="text"
                                            id="phone-number"
                                            name="phone_number"
                                            placeholder="Phone Number"
                                            ref={phoneNumber}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group text_box">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="sub-urb"
                                            name="sub_urb"
                                            placeholder="Your Suburb"
                                            required
                                            ref={suburb}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group text_box">
                                        <input
                                            type="text"
                                            id="year-make-model"
                                            name="make"
                                            placeholder="Year Make Model"
                                            ref={yearMakeModel}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group text_box">
                                        <input
                                            type="text"
                                            id="service"
                                            name="service"
                                            placeholder="Main Service - Air Conditioning, AC, Auto Glass, etc."
                                            ref={service}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group text_box">
                                        <textarea
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="10"
                                            placeholder="Enter Your Message . . ."
                                            ref={message}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn_three">
                                Get Quote
                            </button>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Link to="/">
                                    <button className="btn_three">Home</button>
                                </Link>
                            </div>
                        </form>
                        {/* {emailStatus ? emailStatus : null} */}
                        <div id="success">Your message succesfully sent!</div>
                        <div id="error">
                            Opps! There is something wrong. Please try again
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GetQuote;
