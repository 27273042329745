import React from "react";
import { Reveal } from "react-reveal";

const Rating = () => {
    return (
        <section className="ratings">
            <Reveal effect="fadeInUp" duration={1400}>
                <div className="badge">
                    <div className="badge-lines">
                        <span className="marks">4.9</span>
                        <img
                            src={require("../../img/home/icons/star.svg")}
                            alt="Star"
                        />
                        <img
                            src={require("../../img/home/icons/star.svg")}
                            alt="Star"
                        />
                        <img
                            src={require("../../img/home/icons/star.svg")}
                            alt="Star"
                        />
                        <img
                            src={require("../../img/home/icons/star.svg")}
                            alt="Star"
                        />
                        <img
                            src={require("../../img/home/icons/star.svg")}
                            alt="Star"
                        />
                    </div>
                    <div className="badge-lines">
                        <img
                            src={require("../../img/home/icons/google-logo.png")}
                            alt="Google Logo"
                            className="google-logo"
                        />
                        <span className="reviews">(1000 reviews)</span>
                    </div>
                </div>
                {/* <hr /> */}
            </Reveal>
        </section>
    );
};

export default Rating;
