import React from "react";
import { Reveal } from "react-reveal";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import RepairListItem from "./RepairListItem";
import RepairData from "./RepairData";

const BookService = () => {
    const settings = {
        rows: 2,
        dots: true,
        // centerMode: true,
        infinite: true,
        // centerPadding: "1000px",
        slidesToShow: 4,
        speed: 500,
        slidesToScroll: 2,
        // slidesPerRow: 2,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    // centerMode: true,
                    rows: 1,
                    slidesToShow: 3,
                    slidesPerRow: 1,
                    slidesToScroll: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    dots: false,
                    rows: 1,
                    centerMode: true,
                    slidesToShow: 2,
                    // slidesPerRow: 2,
                    slidesToScroll: 2,
                    // initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    // centerPadding: "60px",
                    dots: false,
                    rows: 1,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    // initialSlide: 0,
                },
            },
        ],
    };

    return (
        <section
            id="book-service"
            className="chat_core_features_area sec_pad"
            style={{ paddingBottom: "0px" }}
        >
            <div className="container">
                <div className="row">
                    <div className="hosting_title chat_title text-center">
                        <Reveal effect="fadeInUp" duration={3000}>
                            <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                                Honest, upfront pricing. <span>Seriously!</span>
                            </h2>
                        </Reveal>
                    </div>
                </div>
                <Reveal effect="fadeInUp" duration={1300}>
                    <Slider {...settings}>
                        {RepairData.Repairs.map((repair) => {
                            return (
                                <RepairListItem
                                    id={repair.id}
                                    name={repair.name}
                                    image={repair.image}
                                    desc={repair.description}
                                    slug={repair.slug}
                                />
                            );
                        })}
                    </Slider>
                    <div className="text-center">
                        <Link to="/Repair">
                            <button className="btn_three" type="submit">
                                See all repairs
                            </button>
                        </Link>
                    </div>
                </Reveal>
                <div
                    className="hosting_title chat_title text-center"
                    style={{ paddingTop: "40px" }}
                >
                    <Reveal effect="fadeInUp" duration={3000}>
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                            Not sure what you need?
                        </h2>
                    </Reveal>
                </div>
                <Reveal effect="fadeInUp" duration={3000}>
                    <img
                        src={require("../../img/home/pictures/car-tech-van.png")}
                        alt="car technicians"
                        style={{ width: "1125px" }}
                        className="img-fluid"
                    />
                    <div className="text-center">
                        <Link to="/Get-Quote">
                            <button className="btn_three" type="submit">
                                Try our Diagnostic Tool
                            </button>
                        </Link>
                    </div>
                </Reveal>
            </div>
        </section>
    );
};

export default BookService;
