import React, { Component } from "react";
import { Link } from "react-router-dom";

class TrackingProductItem2 extends Component {
    render() {
        let {
            itemClass,
            rowclass,
            image,
        } = this.props;
        return (
            <div className={`tracking_pr_item ${itemClass}`}>
                <div className={`row align-items-center ${rowclass}`}>
                    <div className="tracking_bg text-centre">
                        <img
                            src={require("../../img/home/" + image)}
                            alt=""
                            width="450"
                            height="450"
                            className="img-fluid"
                            useMap="#image2"
                        />

                        <map name="image2">
                            <Link to={`/service/Cash-for-Scrap-Cars/cash-for-scrap-cars`}><area shape="poly" coords="0,0,450,0,450,450,0,0" href="#" alt="" /></Link>
                            <Link to={`/service/Cash-for-Old-Cars/cash-for-old-cars`} ><area shape="poly" coords="0,0,0,450,450,450,0,0" href="#" alt="" /></Link>
                        </map>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrackingProductItem2;
