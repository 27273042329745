import React, { useRef, useState } from "react";
import Reveal from "react-reveal/Reveal/";
import emailjs from "@emailjs/browser";

const DesignBanner = () => {
    const [status, setStatus] = useState(undefined);
    const form = useRef();
    const name = useRef(null);
    const email = useRef(null);
    const phone_number = useRef(null);
    const make = useRef(null);
    const service = useRef(null);
    const rego_no = useRef(null);
    const sub_urb = useRef(null);
    const message = useRef(null);

    function sendEmail(e) {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_dg6x5dk",
                "template_8c2so0c",
                form.current,
                "mbqeBuSbZZVyPqePZ"
            )
            .then(
                (result) => {
                    setStatus({ type: "success" });
                    name.current.value = "";
                    email.current.value = "";
                    phone_number.current.value = "";
                    make.current.value = "";
                    sub_urb.current.value = "";
                    rego_no.current.value = "";
                    message.current.value = "";
                },
                (error) => {
                    setStatus({ type: "error" });
                }
            );
    }

    return (
        <section className="seo_home_area">
            <div className="banner_top">
                <div className="container custom_container">
                    <div className="row">
                        <Reveal effect="fadeInUp" duration={1400}>
                            <div
                                className="col-md-12 col-lg-4 contact_form"
                                style={{ paddingLeft: "15px" }}
                            >
                                <form
                                    ref={form}
                                    className="quote_form"
                                    action=""
                                    onSubmit={sendEmail}
                                >
                                    <h4 className="text-center pb-3">
                                        Get free quotation now!
                                    </h4>
                                    {status?.type === "success" && (
                                        <p className="alert alert-success">
                                            Message Send Successfully
                                        </p>
                                    )}
                                    {status?.type === "error" && (
                                        <p className="alert alert-danger">
                                            Message Not Sent!
                                        </p>
                                    )}
                                    <div className="row">
                                        <div className="form-group col-12 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                name="name"
                                                placeholder="Your Name"
                                                required
                                                ref={name}
                                            />
                                        </div>
                                        <div className="col-12 text_box">
                                            <input
                                                type="email"
                                                class="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Your Email"
                                                required
                                                ref={email}
                                            />
                                        </div>
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="phone-number"
                                                name="phone_number"
                                                placeholder="Your Phone Number"
                                                required
                                                ref={phone_number}
                                            />
                                        </div>
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="sub-urb"
                                                name="sub_urb"
                                                placeholder="Your Suburb"
                                                required
                                                ref={sub_urb}
                                            />
                                        </div>
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="make-model"
                                                name="make"
                                                placeholder="Year Make Model"
                                                required
                                                ref={make}
                                            />
                                        </div>
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="rego-no"
                                                name="rego_no"
                                                placeholder="Rego No."
                                                required
                                                ref={rego_no}
                                            />
                                        </div>
                                        <div className="col-12 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="service"
                                                name="service"
                                                placeholder="Main Service - Battery, AC, etc."
                                                required
                                                ref={service}
                                            />
                                        </div>
                                        <div className="form-group col-md-12 text_box">
                                            <textarea
                                                type="text"
                                                class="form-control message"
                                                id="message"
                                                name="message"
                                                placeholder="Your Message"
                                                required
                                                rows="1"
                                                ref={message}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            className="btn_three"
                                            type="submit"
                                        >
                                            Get Quote Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Reveal>
                        <div className="col-md-12 col-lg-8 seo_banner_content mb-5">
                            <div className="saas_home_img">
                                <Reveal effect="fadeInUp" duration={1400}>
                                    <img
                                        src={require("../../img/seo/banner.gif")}
                                        alt="Mechanic Fixing Car"
                                        className="img-fluid"
                                        style={{
                                            boxShadow: "5px 5px 30px #868686",
                                        }}
                                    />
                                </Reveal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default DesignBanner;
