import React, { useContext } from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import ServiceDetails from "../components/Service/ServiceDetails";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import { ServiceDetailsContext } from "../store/ServiceDetailsContext";
import { Helmet } from "react-helmet";
// import { ServiceDetailsProvider as ServiceProvider } from "../store/ServiceDetailsContext";\

const Faq = ({ match }) => {
    const serviceDetailsCtx = useContext(ServiceDetailsContext);
    let title = "";
    let metaDescription = "";
    let metaKeywords = "";

    if (match.params.serviceName == "air-conditioning") {
        title = "Car Air Conditioning Repair in Narre Warren North 3804";
        metaDescription =
            "Our cash for old cars service easily provides you cash for your old or damaged cars.";
        metaKeywords = "Car Air Conditioning Repair in Narre Warren North 3804";
    } else if (match.params.serviceName == "auto-glass") {
        title = "Auto Glass Services in Dandenong South 3175";
        metaDescription =
            "Supercheap Mobile Mechanic provides professional Auto Glass Services in Dandenong South 3175 for your vehicle. Our team of skilled technicians is equipped to handle any auto glass repair or replacement needs you may have.";
        metaKeywords = "Auto Glass Services in Dandenong South 3175";
    } else if (match.params.serviceName == "battery") {
        title = "Car Battery Services in Cranbourne 3977";
        metaDescription =
            "Supercheap Mobile Mechanic offers reliable car battery services for your vehicle. Our team of experienced technicians is equipped to handle any battery issues you may have, from testing and maintenance to replacement.";
        metaKeywords = "Car Battery Services in Cranbourne 3977";
    } else if (match.params.serviceName == "body") {
        title = "Body Repair Services in Dandenong North 3175";
        metaDescription =
            "Supercheap Mobile Mechanic provides professional body repair services for your vehicle. Our team of skilled technicians is equipped to handle any body repair needs ";
        metaKeywords = "Body Repair Services in Dandenong North 3175";
    } else if (match.params.serviceName == "brakes") {
        title = "Brakes Repair Services in Cranbourne east 3977";
        metaDescription =
            "Supercheap Mobile Mechanic offers reliable brake services for your vehicle. Our team of experienced technicians is equipped to handle any brake issues you may have, from brake pad.";
        metaKeywords = "Brakes Repair Services in Cranbourne east 3977";
    } else if (match.params.serviceName == "clutch") {
        title = "Clutch Repair Services in Doveton 3177";
        metaDescription =
            "Supercheap Mobile Mechanic provides expert clutch repair services for your vehicle. Our team of skilled technicians is equipped to handle any clutch issues you may have, from clutch cable replacements to complete clutch system overhauls. ";
        metaKeywords = "Clutch Repair Services in Doveton 3177";
    } else if (match.params.serviceName == "cooling") {
        title = "Radiator Cooling Services in Dandenong 3175";
        metaDescription =
            "Supercheap Mobile Mechanic offers professional radiator cooling services for your vehicle. Our team of experienced technicians is equipped to handle any radiator issues you may have, from coolant leaks to complete radiator replacements.";
        metaKeywords = "Radiator Cooling Services in Dandenong 3175";
    } else if (match.params.serviceName == "electrical") {
        title = "Electrical Repair Services for vehicle in Clyde 3978";
        metaDescription =
            "Supercheap Mobile Mechanic offers comprehensive Electrical Repair Services for vehicle in Clyde 3978. Our team of experienced technicians is equipped to handle any electrical issues you may have, from battery replacements to complete rewiring.";
        metaKeywords = "Electrical Repair Services for vehicle in Clyde 3978";
    } else if (match.params.serviceName == "engine") {
        title = "Engine";
        // metaDescription =
        //     "We are a scrap car removal service provider that not only accepts and remove old and unwanted cars.";
        // metaKeywords = "Scrap Car Removal";
    } else if (match.params.serviceName == "installation") {
        title = "Automotive Installation Services in Clyde 3978";
        metaDescription =
            "Supercheap Mobile Mechanic offers expert installation services for a wide range of automotive components. Our team of experienced technicians is equipped to handle any installation job, from brake pads to suspension systems. Automotive Installation Services in Dandenong 3175.";
        metaKeywords = "Automotive Installation Services in Clyde 3978";
    } else if (match.params.serviceName == "lights") {
        title = "Lights";
        // metaDescription =
        //     "We are a scrap car removal service provider that not only accepts and remove old and unwanted cars but also sell and accept used vans.";
        // metaKeywords = "Sell Used Vans";
    } else if (match.params.serviceName == "scratch-dent-paint") {
        title = "Scratch Dent Paint Services in Dandenong 3175";
        metaDescription =
            "Supercheap Mobile Mechanic offers expert scratch, dent, and paint repair services for your vehicle. Our team of skilled technicians is equipped to handle any cosmetic issues you may have, from minor scratches and dents to major body damage. ";
        metaKeywords = "Scratch Dent Paint Services in Dandenong 3175";
    } else if (match.params.serviceName == "suspension-steering") {
        title = "suspension and steering services in Dandenong 3175";
        metaDescription =
            "Supercheap Mobile Mechanic provides expert suspension and steering services for your vehicle.";
        metaKeywords = "suspension and steering services in Dandenong 3175";
    } else if (match.params.serviceName == "timing-belt") {
        title = "Timing Belt Services in Hallam 3803";
        metaDescription =
            "Super cheap Mobile Mechanic offers expert Timing Belt Services in Hallam 3803 Replacement services for your vehicle. Our team of skilled technicians is equipped to handle any timing belt issues.";
        metaKeywords = "Timing Belt Services in Hallam 3803";
    } else if (match.params.serviceName == "transmission") {
        title = "Transmission Services in Dandenong 3175";
        metaDescription =
            "Supercheap Mobile Mechanic provides expert transmission services for your vehicle.";
        metaKeywords = "Transmission Services in Dandenong 3175";
    } else if (match.params.serviceName == "tyre") {
        title = "Tyre Services in Clyde 3978";
        metaDescription =
            "Supercheap Mobile Mechanic provides expert tyre services for your vehicle. Our team of skilled technicians is equipped to handle any tyre issues you may have, from routine tyre maintenance to complex repairs.";
        metaKeywords = "Tyre Services in Clyde 3978";
    } else if (match.params.serviceName == "wheel") {
        title = "Wheel";
        // metaDescription =
        //     "We are a scrap car removal service provider that not only accepts and remove old and unwanted cars but also sell and accept used cars.";
        // metaKeywords = "Sell Used Cars";
    } else if (match.params.serviceName == "window-tinting") {
        title = "Window Tinting";
        // metaDescription =
        //     "We are a scrap car removal service provider that not only accepts and remove old and unwanted cars but also sell and accept used cars.";
        // metaKeywords = "Sell Used Cars";
    } else if (match.params.serviceName == "wipers") {
        title = "Wipers";
        // metaDescription =
        //     "We are a scrap car removal service provider that not only accepts and remove old and unwanted cars but also sell and accept used cars.";
        // metaKeywords = "Sell Used Cars";
    }

    return (
        <div className="body_wrapper">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
            </Helmet>

            <CustomNavbar
                slogo="sticky_logo"
                cClass="custom_container p0"
                mClass="menu_four"
                nClass="w_menu"
            />
            {/* <CustomNavbar slogo="sticky_logo" cClass="custom_container p0" mClass="menu_four" nClass="w_menu ml-auto mr-auto" /> */}
            {/* <ServiceProvider> */}
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="Img-05.jpg"
                Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
                // padding="0px"
                Ptitle={match.params.serviceName}
            />
            {/* </ServiceProvider> */}
            <ServiceDetails
                title={match.params.serviceName}
                icon={match.params.serviceImage}
            />
            <FooterTwo FooterData={FooterData} />
        </div>
    );
};
export default Faq;
