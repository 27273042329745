import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Reveal } from "react-reveal";

// Components
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from "../components/Footer/FooterTwo";

// Data
import RepairData from "../components/Home/RepairData";
import FooterData from "../components/Footer/FooterData";

const Repair = () => {
    return (
        <div className="body_wrapper">
            <Helmet>
                <title>Repair List</title>
                {/* <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} /> */}
            </Helmet>

            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />

            <section
                id="repair-service"
                className="chat_core_features_area sec_pad"
                style={{ paddingTop: "150px" }}
            >
                <div className="container">
                    <div className="row">
                        <div
                            className="hosting_title chat_title text-center"
                            style={{ marginBottom: "0px" }}
                        >
                            <Reveal effect="fadeInUp" duration={1300}>
                                <h2
                                    className="wow fadeInUp"
                                    data-wow-delay="0.2s"
                                >
                                    <span>Repair List</span>
                                </h2>
                            </Reveal>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <Reveal effect="fadeInUp" duration={1300}>
                                {RepairData.Repairs.map((repair) => {
                                    return (
                                        <div className="col-md-4">
                                            <div key={repair.id}>
                                                <button className="btn_three">
                                                    {repair.name}
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Reveal>
                        </div>
                    </div>
                </div>
            </section>

            <FooterTwo FooterData={FooterData} />
        </div>
    );
};

export default Repair;
