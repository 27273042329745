import React from "react";
import { Reveal } from "react-reveal";

const CoreFeatures = ({ video1Name, video2Name, video3Name }) => {
    return (
        <section id="process" className="chat_core_features_area sec_pad">
            <div className="container">
                <div className="hosting_title chat_title text-center">
                    <Reveal effect="fadeInUp" duration={3000}>
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                            No more<span> waiting</span> at the auto shop
                        </h2>
                    </Reveal>
                </div>
                <Reveal effect="fadeInUp" duration={1400}>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="chat_features_item wow fadeInUp">
                                <div className="round">
                                    <img
                                        className="img-fluid"
                                        src={require(`../../video/call-us.gif`)}
                                        alt="Call Us"
                                    />
                                </div>
                                <a href="javascript:void(0)" className="pt-4">
                                    <h4>Book online</h4>
                                </a>
                                <div className="d-flex">
                                    <img
                                        className="number"
                                        src={require("../../img/home4/1.png")}
                                        alt=""
                                    />
                                    <p>
                                        You can communicate easily with us by a
                                        single button click and we are ready to
                                        serve you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="chat_features_item">
                                <div className="round">
                                    <img
                                        className="img-fluid"
                                        src={require(`../../video/we-come-to-you.gif`)}
                                        alt="We Come to You"
                                    />
                                </div>
                                <a href="javascript:void(0)" className="pt-4">
                                    <h4>We come to you</h4>
                                </a>
                                <div className="d-flex">
                                    <img
                                        className="number"
                                        src={require("../../img/home4/2.png")}
                                        alt=""
                                        height="90"
                                    />
                                    <p>
                                        We are available 24/7 at your service.
                                        Just let us know how can we serve you
                                        and you will have our response in the
                                        blink of an eye.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="chat_features_item wow fadeInUp"
                                data-wow-delay="0.4s"
                            >
                                <div className="round">
                                    <img
                                        className="img-fluid"
                                        src={require(`../../video/we-fix-your-car.gif`)}
                                        alt="Fixing Car"
                                    />
                                </div>
                                <a href="javascript:void(0)" className="pt-4">
                                    <h4>Repair on the go</h4>
                                </a>
                                <div className="d-flex">
                                    <img
                                        className="number"
                                        src={require("../../img/home4/3.png")}
                                        alt=""
                                    />
                                    <p>
                                        We're committed to providing fast,
                                        reliable service to get you back on the
                                        road as soon as possible.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Reveal>
            </div>
        </section>
    );
};
export default CoreFeatures;
