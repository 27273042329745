import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Blogrightsidebar from "./Blogrightsidebar";
import BlogGridItem from "./BlogGridItem";
import ServiceData from "../Service/ServiceData";
import { Link } from "react-router-dom";

const BlogGrid2 = ({ BlogData }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Blogs</title>
                <meta name="description" content="Super Cheap Mechanic|Blogs" />
                <meta
                    name="keywords"
                    content="causes of black smoke, reasons for car shaking and diagnosing car shakes, car smell prevention, spar plug solution, damages vehicles solution, check engine lights, how to clean battery terminals, steps for changing oil"
                />
            </Helmet>
            <section className="blog_area_two sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 blog_grid_info">
                            <div className="row">
                                {BlogData.Blogs.slice(10, 20).map((blog) => {
                                    return (
                                        <BlogGridItem
                                            date={blog.date}
                                            image={blog.image}
                                            Title={blog.title}
                                            description={blog.excerpt}
                                            body={blog.body}
                                            slug={blog.slug}
                                            btn="Read More"
                                            // comment="3"
                                            key={blog.id}
                                        />
                                    );
                                })}
                            </div>
                            <ul className="list-unstyled page-numbers shop_page_number text-left mt_30">
                                <li>
                                    <Link
                                        to="/blog-posts/1"
                                        className="page-numbers"
                                    >
                                        1
                                    </Link>
                                </li>
                                <li>
                                    <span
                                        aria-current="page"
                                        className="page-numbers current"
                                    >
                                        2
                                    </span>
                                </li>
                                {/* <li>
                                <Link
                                    to="/blog-posts/3"
                                    className="page-numbers"
                                >
                                    3
                                </Link>
                            </li> */}
                                {/* <li>
                                <a className="next page-numbers" href=".#">
                                    <i className="ti-arrow-right"></i>
                                </a>
                            </li> */}
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <Blogrightsidebar BlogData={BlogData} />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
export default BlogGrid2;
